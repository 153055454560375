﻿import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getBlogsThunk, selectBlogs } from "./blogsSlice"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { CreateBlog } from "./createBlog"

export const Blogs = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const blogs = useAppSelector(selectBlogs)

  useEffect(() => {
    dispatch(getBlogsThunk())
  }, [])

  const RenderBlogs = () => {
    return blogs.map((blog) => {
      return (
        <button onClick={() => navigate("/blog/" + blog.id)}>
          <h1>{blog.title}</h1>
        </button>
      )
    })
  }

  return (
    <>
      <h1>Blogs</h1>
      {blogs && <RenderBlogs />}
      <CreateBlog />
    </>
  )
}
