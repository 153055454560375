﻿import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {createBlogThunk, deleteBlogThunk, getBlogThunk, selectBlog} from "./blogsSlice"
import { ReactElement, useEffect } from "react"
import {EditBlog} from "./editBlog";
import {useNavigate} from "react-router-dom";

export interface Props {
  id: string
}

export const Blog = (props: Props): ReactElement => {
  const dispatch = useAppDispatch()
  const blog = useAppSelector(selectBlog)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getBlogThunk(props.id))
  }, [])
  
  const deleteBlog = () => {
    const result = dispatch(deleteBlogThunk(props.id))
    result.then((res) => {
      navigate("/")
    })
  }

  return (
    <>
      <p>hi</p>
      {blog && (
        <>
          <h1>{blog.title}</h1>
          <p>{blog.content}</p>
          <EditBlog id={props.id}/>
          <button title={"Delete"} onClick={() => deleteBlog()}>Delete</button>
        </>
      )}
    </>
  )
}
