﻿import { Blog } from "./blog.types"

export const getBlogs = async () => {
  const url = `${BASEURL}/blogs`
  return await fetch(url, { method: "GET" })
}

export const getBlog = async (id: string) => {
  const url = `${BASEURL}/blogs/${id}`
  return await fetch(url, { method: "GET" })
}

export const createBlog = async (blog: Blog) => {
  const url = `${BASEURL}/blogs/`
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(blog),
    headers: { "Content-Type": "application/json" },
  })
}

export const editBlog = async (blog: Blog) => {
  const url = `${BASEURL}/blogs/${blog.id}`;
  const payload: any = {
    ...blog
  }
  return await fetch(url, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  })
}

export const deleteBlog = async (id: string) => {
  const url = `${BASEURL}/blogs/${id}`
  return await fetch(url, { method: "DELETE" })
}

const BASEURL = import.meta.env.PROD
  ? "https://oikzreactapiterraform.azurewebsites.net/api"
  : "http://localhost:5091/apiv2"
