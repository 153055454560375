﻿import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {createBlog, deleteBlog, editBlog, getBlog, getBlogs} from "./blogsAPI"
import { RootState } from "../../app/store"
import { Blog, Blogs } from "./blog.types"

export const getBlogsThunk = createAsyncThunk("blogs/getBlogs", async () => {
  const response = await getBlogs()
  return response.json()
})

export const getBlogThunk = createAsyncThunk(
  "blogs/getBlog",
  async (id: string) => {
    const response = await getBlog(id)
    return response.json()
  },
)

export const createBlogThunk = createAsyncThunk(
  "blogs/createBlog",
  async (blog: Blog) => {
    const response = await createBlog(blog)
    return await response.json()
  },
)

export const editBlogThunk = createAsyncThunk(
    "blogs/createBlog",
    async (blog: Blog) => {
      const response = await editBlog(blog)
      return await response.json()
    },
)

export const deleteBlogThunk = createAsyncThunk(
    "blogs/deleteBlog", 
    async (id: string) => {
  const response = await deleteBlog(id)
  return response.json()
})

export interface BlogsState {
  blog?: Blog
  blogs: Blogs
  loading: boolean
}

const initialState: BlogsState = {
  blog: undefined,
  blogs: [],
  loading: true,
}

export const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getBlogsThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(getBlogsThunk.fulfilled, (state, action) => {
        state.loading = false
        state.blogs = action.payload
      })
      .addCase(getBlogsThunk.rejected, (state) => {
        state.loading = false
      })
      .addCase(getBlogThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(getBlogThunk.fulfilled, (state, action) => {
        state.loading = false
        state.blog = {
          id: action.payload.id,
          title: action.payload.title,
          content: action.payload.content,
        }
      })
      .addCase(getBlogThunk.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectBlog = (state: RootState) => state.blogs.blog
export const selectBlogs = (state: RootState) => state.blogs.blogs
export default blogsSlice.reducer
