import React from "react"
import ReactDOM from "react-dom/client"
import {Provider} from "react-redux"
import {store} from "./app/store"
import "./index.css"
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomeRoute from "./routes/homeRoute";
import BlogRoute from "./routes/blogRoute";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeRoute/>,
    },
    {
        path: "/blog/:id",
        element: <BlogRoute/>
    }
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>,
)
