﻿import {useState} from "react"
import {useAppDispatch} from "../../app/hooks"
import {createBlogThunk} from "./blogsSlice"
import {useNavigate} from "react-router-dom"

export const CreateBlog = () => {
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleSubmit = () => {
        const result = dispatch(createBlogThunk({title, content}))
        result.then((res) => {
            navigate("/blog/" + res.payload["id"])
        })
    }

    return (
        <>
            <h1>Create Blog</h1>
            <label htmlFor="title">Title</label>
            <input
                type="text"
                id="title"
                name="title"
                value={title || ""}
                onChange={(event) => setTitle(event.target.value)}
            />
            <label htmlFor="content">Content</label>
            <textarea
                id="content"
                name="content"
                value={content || ""}
                onChange={(event) => setContent(event.target.value)}
            />
            <button onClick={() => handleSubmit()}>Submit</button>
        </>
    )
}
