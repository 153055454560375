﻿import {useState} from "react"
import {useAppDispatch} from "../../app/hooks"
import {editBlogThunk, getBlogThunk} from "./blogsSlice"
import {useNavigate} from "react-router-dom"


export interface Props {
    id: string
}
export const EditBlog = (props: Props) => {
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const dispatch = useAppDispatch()

    const handleSubmit = () => {
        const id = props.id;
        const result = dispatch(editBlogThunk({id, title, content}))
        result.then(() => {
            dispatch(getBlogThunk(props.id))
        })
    }

    return (
        <>
            <h1>Edit Blog</h1>
            <label htmlFor="title">Title</label>
            <input
                type="text"
                id="title"
                name="title"
                value={title || ""}
                onChange={(event) => setTitle(event.target.value)}
            />
            <label htmlFor="content">Content</label>
            <textarea
                id="content"
                name="content"
                value={content || ""}
                onChange={(event) => setContent(event.target.value)}
            />
            <button onClick={() => handleSubmit()}>Submit</button>
        </>
    )
}
